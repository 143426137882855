<template>
  <div class="audio-player">
    <v-progress-circular
      :size="50"
      color="primary"
      indeterminate
      class="loading"
      v-show="isLoading"
    />
    <div
      v-show="!isLoading"
      ref="player"
    />
  </div>
</template>

<script>
import APlayer from 'aplayer/dist/APlayer.min.js'
import 'aplayer/dist/APlayer.min.css'
import { requests } from '@/plugins/Amplify'
import capitalize from 'capitalize-pt-br'

let listAudios = []

export default {
  data () {
    const isLoading = true
    return { isLoading }
  },

  mounted () {
    const vm = this
    vm.aPlayer = null
    window.addEventListener('message', vm.onMessage, true)
    window.opener.postMessage(JSON.stringify({ action: 'playerOpened' }), window.opener.location.origin)
  },

  beforeDestroy () {
    const vm = this
    if (vm.interval) clearInterval(vm.interval)
    if (vm.aPlayer) vm.aPlayer.destroy()
    window.removeEventListener('message', vm.onMessage, true)
  },

  methods: {
    onMessage (event) {
      const vm = this
      if (event.origin !== window.opener.location.origin) return

      const data = parseJson(event.data)
      if (data?.action !== 'playAudios') return
      if (!data.audios?.length) return

      if (!vm.aPlayer) {
        vm.aPlayer = new APlayer({
          container: vm.$refs.player,
          audio: [],
          loop: 'none',
          listMaxHeight: '330px'
        })
      }

      vm.aPlayer.pause()
      vm.aPlayer.list.clear()
      vm.isLoading = true

      for (const oldAudio of listAudios) {
        const newAudio = data.audios.find((audio) => audio.id === oldAudio.id)
        if (newAudio) newAudio.url = oldAudio.url
      }

      listAudios = [].concat(data.audios, listAudios)
      const ids = listAudios.map(({ id }) => id)
      listAudios = listAudios.filter(({ id }, index) => ids.indexOf(id) === index)

      Promise.all(listAudios.map(async (audio) => {
        if (!audio.url) audio.url = await requests.getAudioStreamLink(audio).then(({ url }) => url).catch(() => null)
        return audio
      })).then((audios) => {
        const ids = audios.map(({ id }) => id)
        listAudios = audios.filter(({ id }, index) => ids.indexOf(id) === index)
        vm.aPlayer.list.clear()

        vm.aPlayer.list.add(listAudios.map((audio) => ({
          name: audio.assunto,
          artist: [
            capitalize(audio.dirigente || audio.orador || audio.autor),
            capitalize(audio.nucleo || ''),
            capitalize(audio.album || ''),
            capitalize(audio.sessao || '')
          ].filter(v => v).join(' - ').replace('Núcleo ', '').replace('Sessão de ', '').trim(),
          url: audio.url,
          cover: 'https://udv-mdt.s3.amazonaws.com/assets/images/generico.png'
        })))

        vm.aPlayer.list.switch(0)
        vm.aPlayer.play()
        vm.isLoading = false
      })
    }
  }
}

const parseJson = (str) => {
  if (!str) return null

  try {
    return JSON.parse(str)
  } catch {
    return null
  }
}

</script>
<style lang="sass">
  .audio-player
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    height: 400px

    .loading
      flex: 1
</style>
